.navContainer {
  background: #0494ca 0% 0% no-repeat padding-box;
  bottom: 0px;
  width: 100%;
  z-index: 3;

  .navWrapper {
    display: flex;
    flex-direction: column;

    .navLogo {
      margin-top: 45px;
    }

    .navTabsContainer {
      display: flex;
      flex-wrap: wrap;

      .navTabItem {
        flex: 1;
        margin-top: 45px;

        .navTitle {
          cursor: pointer;
          user-select: none;
          text-align: left;
          font: normal normal bold 18px/27px Poppins;
          letter-spacing: 0px;
          color: #ffffff;
          width: 11em;
        }

        .navTitleMobile {
          cursor: pointer;
          user-select: none;
          text-align: left;
          font: normal normal bold 14px/27px Poppins;
          letter-spacing: 0px;
          color: #ffffff;
          width: 11em;
        }

        .navSocialLinks {
          .navSocialLink {
            cursor: pointer;
            user-select: none;
            font: normal normal normal 14px/21px Poppins;
            letter-spacing: 0px;
            color: #ffffff;
          }
        }

        .navLinks {
          cursor: pointer;
          user-select: none;

          .navLink {
            font: normal normal normal 14px/21px Poppins;
            letter-spacing: 0px;
            color: #ffffff;
            width: 11em;
          }
        }
      }

      .navAppStore {
        flex: 2;
        margin-top: 45px;

        .navTitle {
          text-align: left;
          font: normal normal bold 18px/27px Poppins;
          letter-spacing: 0px;
          color: #ffffff;
        }

        .navAppWrapper {
          display: flex;
          flex-direction: column;
          gap: 5px;

          .navAppInfo {
            flex: 2;

            .navAppDescription {
              text-align: left;
              font: normal normal normal 14px/18px Poppins;
              letter-spacing: 0px;
              color: #ffffff;
            }
          }

          .navAppLogos {
            flex: 1;
            display: flex;
            margin-top: 10px;
            gap: 15px;

            div {
              cursor: pointer;
              user-select: none;

              img {
                max-width: 150px;
                width: 100%;
              }
            }
          }
        }
      }
    }

    .footerYear {
      font: normal normal normal 18px/26px Poppins;
      letter-spacing: 0.54px;
      color: #ffffff;
      margin-top: 45px;
      margin-bottom: 45px;
    }

    .footerYearMobile {
      font: normal normal normal 14px/20px Poppins;
      letter-spacing: 0.54px;
      color: #ffffff;
      margin-top: 45px;
      margin-bottom: 45px;
    }
  }
}

@media (max-width: 991px) {
  .navContainer {
    .navWrapper {
      .navTabsContainer {
        .navTabItem {
          .navTitle {
            cursor: pointer;
            user-select: none;
            text-align: left;
            font: normal normal bold 16px/27px Poppins;
            letter-spacing: 0px;
            color: #ffffff;
            width: 11em;
          }
        }

        .navAppStore {
          .navAppWrapper {
            flex-direction: column;

            .navAppLogos {
              flex-direction: row;
              margin-top: 15px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .navContainer {
    .navWrapper {
      .navTabsContainer {
        flex-direction: column;

        .navAppStore {
          margin-top: 15px;

          .navAppWrapper {
            .navAppLogos {
              flex-direction: row;
              margin-top: 0;
            }
          }
        }
      }

      .footerYear {
        font: normal normal normal 18px/26px Poppins;
        letter-spacing: 0.54px;
        color: #ffffff;
        margin-top: 15px;
        margin-bottom: 15px;
      }

      .footerYearMobile {
        font: normal normal normal 13px/20px Poppins;
        letter-spacing: 0.54px;
        color: #ffffff;
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }
  }
}

@media (max-width: 575px) {
  .navContainer {
    .navWrapper {
      padding: 0 5%;
    }
  }
}
