@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
:root {
  --white: #ffffff;
  --dark-gray: #333333;
  --light-gray: #383838;
  --orange: #e93f25;
  --light-blue: #ecfaff;
  --dark-blue: #0494ca;

  --font-primary: #333333;
  --font-secondary: #0494ca;
}

input,
label {
  cursor: pointer;
}

input[type="radio"] {
  height: 1rem;
  width: 1rem;
  margin-right: 0.5rem;
  background-color: #fff;
  appearance: none;
  color: #aaaaaa;
  border-radius: 50%;
  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: scale(0);
  transition: 90ms transform ease-in-out;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

.radio-blue {
  accent-color: #0494ca;
  border: 1px solid #0494ca;
}

.radio-blue::before {
  box-shadow: inset 1em 1em #0494ca;
}

.radio-green {
  accent-color: #82c36d;
  border: 1px solid #82c36d;
}

.radio-green::before {
  box-shadow: inset 1em 1em #82c36d;
}

p {
  font-size: 16px;
  font-weight: 300;
}

a {
  text-decoration: none !important;
}

/* Custom Coloring for Ant.Design Carousel */
.ant-carousel .slick-dots li button {
  background: #000;
  opacity: 0.4;
}

.ant-carousel .slick-dots li.slick-active button {
  opacity: 1;
  background: #000;
}

@font-face {
  font-family: "Banner";
  src: url("../public/fonts/brightwall.ttf");
}

@font-face {
  font-family: "Damion";
  src: url("../public/fonts/Damion-Regular.ttf");
}

/** Partners Animated Slider **/
@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 11));
  }
}

.slider {
  background: white;
  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 80%;
  margin-bottom: 100px;
}

.slider::before,
.slider::after {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}

.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.slider::before {
  left: 0;
  top: 0;
}

.slider .slide-track {
  animation: scroll 40s linear infinite;
  display: flex;
  width: calc(250px * 12);
}

.slider .slide {
  height: 100px;
  width: 250px;
}

/** End Partners Animated Slider **/

/* 404 Page CSS */
.error-info-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px 115px;

  @media only screen and (max-width: 732px) {
    padding: 60px 20px 0px 20px;
  }
}

.error-404-box {
  background-color: #ecfaff;
  max-width: fit-content;
  padding: 20px 70px;
  display: flex;
  justify-self: center;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;

  @media only screen and (max-width: 732px) {
    padding: 12px 42px;
    border-radius: 4px;
  }
}

.error-404-box h1 {
  font: normal normal bold 60px/60px Poppins;
  letter-spacing: 0px;
  color: #0494ca;

  @media only screen and (max-width: 732px) {
    font: normal normal bold 24px/24px Poppins;
    margin: auto;
  }
}

.error-404-box p {
  font: normal normal 500 30px/30px Poppins;
  letter-spacing: 0px;
  color: #0494ca;
}

.error-image-box {
  @media only screen and (max-width: 732px) {
    margin-left: 0;
  }
}

.error-image-box-img {
  max-width: 500px;

  @media only screen and (max-width: 732px) {
    padding-left: 15px;
    max-width: 90%;
  }
}

.error-description {
  text-align: left;
  font: normal normal bold 22px/25px Poppins;
  letter-spacing: 0px;
  color: #0494ca;
  margin-top: 30px;

  @media only screen and (max-width: 732px) {
    font: normal normal bold 20px/22px Poppins;
    width: 90%;
  }
}

.error-navigation {
  margin-top: 30px;
}

.error-navigation-description {
  text-align: left;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0.4px;
  color: #333333;

  @media only screen and (max-width: 732px) {
    font: normal normal normal 14px/20px Poppins;
  }
}

.error-navigation-links {
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0.4px;
  color: #333333;
  margin-top: 30px;
  text-decoration: underline;
  cursor: pointer;
  user-select: none;

  @media only screen and (max-width: 732px) {
    font: normal normal normal 14px/20px Poppins;
    margin-top: 4px;
    margin-left: -16px;
  }
}

.error-navigation-links ul {
  list-style-type: circle;
}

/** End 404 Page **/

.popover {
  max-width: 100% !important;
  max-height: 100% !important;
}

.displayNone {
  display: none;
}

/* animation section */

.slideIn {
  animation-duration: 1s;
  animation-name: slidein;
}

.slideOut {
  animation-duration: 1s;
  animation-name: slideout;
}

.slideBack {
  animation-duration: 1s;
  animation-name: slideback;
}

.slideForward {
  animation-duration: 1s;
  animation-name: slideforward;
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

@keyframes slideout {
  from {
    margin-left: 0%;
    width: 100%;
  }

  to {
    margin-left: -100%;
    width: 300%;
  }
}

@keyframes slideback {
  from {
    margin-left: -100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

@keyframes slideforward {
  from {
    margin-left: 0%;
    width: 100%;
  }

  to {
    margin-left: 100%;
    width: 300%;
  }
}
